import React from 'react';
import { graphql } from 'gatsby';

import { SiteLayout } from '~/src/components/Layout/SiteLayout';
import { getCategories } from '~/src/mdx/templates/learning/helpers/utils';
import { FinalCTA } from '~/src/components/FinalCTA';
import { CATEGORY_DESCRIPTIONS } from '~/src/learning/categories';
import { Category } from './components/Category';
import {
  LearningCenterHero,
  LearningCenterContent,
  CategoriesContainer,
} from './styles/styled-components';
import '~/src/mdx/templates/learning/helpers/fragments';

export const frontmatter = {
  title: 'Learning Center',
  description:
    'Uploadcare Learning Center. Get insights on all things network, media and UGC. Learn from fellow Uploadcare developers and build better products together.',
};

const CaseStudyIndex = ({ location, data: { allMdx } }) => {
  const { pathname } = location;
  const categories = Array.from(getCategories(allMdx)).sort();

  return (
    <SiteLayout meta={frontmatter} pathName={pathname}>
      <LearningCenterHero verticalBase="60" horizontalBase="120" title="Learning Center">
        <p>Uploadcare Learning Center. Get insights on all things network, media and UGC.</p>
        <p>Learn from fellow Uploadcare developers and build better products together.</p>
      </LearningCenterHero>
      <LearningCenterContent>
        <CategoriesContainer>
          {categories.map((category) => {
            const [categoryName, articles] = category;

            return (
              <Category
                key={categoryName}
                heading={categoryName}
                description={CATEGORY_DESCRIPTIONS[categoryName]}
                articles={articles}
              />
            );
          })}
        </CategoriesContainer>
      </LearningCenterContent>
      <FinalCTA
        btnSignUpProps={{
          'data-analytics': 'btn_sign-up-learning-center',
        }}
        btnScheduleDemoProps={{
          'data-analytics': 'btn_schedule-demo-learning-center',
        }}
      />
    </SiteLayout>
  );
};

export default CaseStudyIndex;

export const query = graphql`
  query ($sourceName: String!) {
    allMdx(
      filter: { exports: { disabled: { ne: true } }, fields: { sourceName: { eq: $sourceName } } }
    ) {
      edges {
        node {
          ...ArticleBase
          ...ArticleExports
        }
      }
    }
  }
`;
