import { STORY_TYPE_ENUM } from '~/src/mdx/components/StoryCards/types';
import { CATEGORIES_PATH_MAP } from '~/src/learning/categories';

export const getArticleMeta = (article) => ({
  id: article.id,
  frontmatter: article.frontmatter,
  name: article.fields?.name,
  path: article.fields?.path,
  code: article.body,
  type: STORY_TYPE_ENUM.LEARNING,
});

export const getCategories = (allMdx) => {
  const clusters = new Map();

  allMdx.edges.forEach(({ node }) => {
    const articleMeta = getArticleMeta(node);
    const directory = articleMeta.path.split('/')[2];
    const category = CATEGORIES_PATH_MAP[directory] ?? directory.replaceAll('-', ' ');

    clusters.has(category)
      ? clusters.get(category)?.push(articleMeta)
      : clusters.set(category, [articleMeta]);
  });

  return clusters;
};
