export const CATEGORIES_PATH_MAP = {
  cdn: 'CDN',
  dns: 'DNS',
  'file-uploader': 'File uploader',
  guides: 'Guides',
  glossary: 'Glossary',
};

export const CATEGORY_DESCRIPTIONS = {
  [CATEGORIES_PATH_MAP.cdn]: 'Master CDNs – discover simple answers to complicated questions.',
  [CATEGORIES_PATH_MAP.dns]: 'The fundamentals of DNS and networking.',
  [CATEGORIES_PATH_MAP['file-uploader']]:
    'How to upload files like a pro. A guide to uploading all the file types.',
  [CATEGORIES_PATH_MAP.guides]:
    'How to transform your files and images. A guide to transformations using uploadcare CDN.',
  [CATEGORIES_PATH_MAP.glossary]:
    'Glossary by Uploadcare – no fluff, all the mysterious acronyms explained.',
};
