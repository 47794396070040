import styled, { css } from 'styled-components';
import { Link } from '~/src/components/Link';

export const CategoryContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;

    ${tablet()} {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 7;
      }

      &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 4;
      }

      &:nth-child(5) {
        grid-column-start: 4;
        grid-column-end: 7;
      }
    }
  `
);

export const CategoryHeading = styled.h2`
  font-size: 22px;
  color: #000000;

  ::first-letter {
    text-transform: uppercase;
  }
`;

export const CategoryDescription = styled.p`
  font-size: 14px;
`;

export const CategoryArticles = styled.ul`
  display: grid;
  grid-gap: 0.6em;
  font-size: 16px;
  padding-left: 1em;

  li {
    line-height: 1.2;
  }
`;

export const CategoryArticleLink = styled(Link)`
  text-decoration: none;
  color: #3771ff;
  line-height: 1.4;
`;
