/* Vendors */
import React from 'react';
import styled, { css } from 'styled-components';

/* Components */
import { Content } from '../Content/Content';

/* Utils */

/* Types */

/* Styles */
const getStyle = ({ visual }) => {
  if (visual === 'dark') {
    return css`
      background-color: #40464b;
      color: var(--color-light);
    `;
  }
};

const Component = styled.header`
  text-align: center;

  ${getStyle};
`;

const Title = styled.h1`
  padding-bottom: 24px;
  padding-top: 0;

  font-size: 48px;
  line-height: 56px;

  @media (max-width: 743px) {
    font-size: 36px;
    line-height: 40px;
  }

  @media (min-width: 1250px) {
    font-size: 62px;
    line-height: 64px;
  }
`;

const Description = styled.p`
  padding-bottom: 32px;
`;

/* Component */
export const Hero = ({
  className,
  visual = 'light',
  title,
  description,
  children,
  ...paddings
}) => (
  <Component className={className} visual={visual}>
    <Content {...paddings}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {children}
    </Content>
  </Component>
);

/* default props */
Hero.defaultProps = { visual: 'light' };
