import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';

import { Hero } from '~/src/components/Hero/Hero';

export const LearningCenterHero = styled(Hero)`
  p {
    margin: 0 10px;
  }

  h1 {
    font-weight: normal;
    font-size: 68px;
    line-height: 68px;

    @media (max-width: 743px) {
      font-size: 36px;
      line-height: 40px;
    }
  }
`;

export const LearningCenterContent = styled.div`
  background-color: #faf8f3;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const CategoriesContainer = styled(Wrapper)(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr;

    ${tablet()} {
      align-items: start;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 35px 15px;
    }
  `
);
