import React from 'react';

import {
  CategoryContainer,
  CategoryArticles,
  CategoryDescription,
  CategoryHeading,
  CategoryArticleLink,
} from './styles/Category.styles';

export const Category = ({ heading, description, articles = [] }) => (
  <CategoryContainer>
    <CategoryHeading>{heading}</CategoryHeading>
    {Boolean(description) && <CategoryDescription>{description}</CategoryDescription>}

    {articles.length > 0 && (
      <CategoryArticles>
        {articles.map(({ frontmatter: { title }, path }) => (
          <li key={title}>
            <CategoryArticleLink to={path}>{title}</CategoryArticleLink>
          </li>
        ))}
      </CategoryArticles>
    )}
  </CategoryContainer>
);
